import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { AuthContext } from "../App";

// components
import InvestorBox from "./InvestorBox";
import AdvisorBox from "./AdvisorBox";
import LoginFooter from "./LoginFooter";

// css
import "./Login.css";
import "../components/modal/Modal.css";

// assets
import sdcfLogo from "../assets/images/logo1-default.png";
import loginHeroImg from "../assets/images/login-bg.jpg";
import arrowImg from "../assets/images/new.png";
import tax1File from "../assets/files/TY21_SDCF_F8937_Common - Investors.pdf";
import tax2File from "../assets/files/TY22_SDCF_F8937_Common - Investors.pdf";

const LoginPage = () => {
  const ROOT_URL = process.env.REACT_APP_API_URL;
  let history = useHistory();

  const { dispatch } = useContext(AuthContext);

  const initialState = {
    isSubmitting: false,
    errorMessage: null,
  };

  const [data, setData] = useState(initialState);
  const [investor, setInvestor] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const SSOLogin = (token) => {
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    let product_key = process.env.REACT_APP_PRODUCT_KEY;
    let ssauthtoken = token;
    let requestData = { product_key: product_key, token: ssauthtoken };
    let spRequest = {
      spName: "InvestorPortal_SSOLogin",
      jsonInput: JSON.stringify(requestData),
      headers: { "Content-Type": "application/json" },
    };
    // let spRequestBundle = JSON.stringify(spRequest);
    axios({ method: "post", url: ROOT_URL, data: spRequest })
      .then((response) => {
        const login_response = JSON.parse(response.data);
        if (login_response.Rows.length === 1) {
          sessionStorage.setItem(
            "viewAssetGallery",
            login_response.Rows[0].view_asset_gallery
          );
          sessionStorage.setItem(
            "investorMode",
            login_response.Rows[0].investor_mode
          );
          sessionStorage.setItem(
            "productId",
            login_response.Rows[0].product_id
          );
          sessionStorage.setItem(
            "profileId",
            login_response.Rows[0].profile_id
          );
          sessionStorage.setItem(
            "privatePlacementId",
            login_response.Rows[0].private_placement_id
          );
          sessionStorage.setItem(
            "allowESignature",
            login_response.Rows[0].allow_esignature
          );
          sessionStorage.setItem("viewOnly", login_response.Rows[0].view_only);
          sessionStorage.setItem(
            "realLoggedInProfileId",
            login_response.Rows[0].real_logged_in_profile_id
          );
          sessionStorage.setItem("token", response.headers.authorization);
          dispatch({ type: "LOGIN", payload: login_response.Rows[0] });
          // After the api call is successful, and the loader page is displayed we need to push home to take us home, or else we get infinite loader loop
          history.push("/home");
        } else {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: login_response.error_message,
          });
          // console.log(login_response.error_message);
        }
        return { resp: response };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const ssValue = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    // console.log(Object.keys(ssValue))
    // console.log(ssValue)

    if (Object.keys(ssValue).length !== 0) {
      // if the query string in the URL exists, run the SSOLogin function
      SSOLogin(ssValue.id); // pass the host and SSO value
    }
  }, []);

  // investor login -- financial advisor login switch
  const renderLoginBox = () => {
    if (investor) {
      return (
        <div>
          <InvestorBox /> <br />
          <h5 className="login-page-heading">
            If you are a Financial Advisor{" "}
            <span
              onClick={() => {
                setInvestor(false);
              }}
              className="d-green"
            >
              click here
            </span>{" "}
            to log in
          </h5>
          <h5 className="login-page-heading login-heading-negative-margin">
            <img src={arrowImg} alt="new-arrow" className="login-new-arrow" />
            If you are an Existing Investor{" "}
            <span
              onClick={() => {
                window.open(
                  "https://secureaccountview.com/BFWeb/clients/strategicfunds/index"
                );
              }}
              className="d-green"
            >
              click here
            </span>{" "}
            to log in
          </h5>
          <h5
            className="login-page-heading hover"
            onClick={() => setModalShow(true)}
          >
            View Tax Information
          </h5>
        </div>
      );
    } else {
      return (
        <div>
          <AdvisorBox />
          <p className="register-here-p">
            <span
              className="reset-pw-link d-green fw-6"
              onClick={() => {
                setInvestor(true);
              }}
            >
              Click here{" "}
            </span>
            for Investor Login
          </p>
        </div>
      );
    }
  };
  return (
    <>
      {data.isSubmitting ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <div className="login-header">
            <img src={sdcfLogo} alt="sdcf logo" className="sdcf-login-logo" />
            <div className="login-header-text-wrapper">
              <h3>A PRIVATE INVESTMENT OFFERING</h3>
              <p>FOR ACCREDITED INVESTORS ONLY</p>
            </div>
          </div>
          <img src={loginHeroImg} alt="hero" className="full-width" />
          <div className="login-lower-content-flex-container">
            <div className="login-lower-title-wrapper">
              <h2 className="d-gray-4">
                <b>LOG IN</b>
              </h2>
              <h5 className="d-green fw-4">FOR YOUR PERSONAL ACCESS</h5>
              {data.errorMessage && (
                <p className="form-error">{data.errorMessage}</p>
              )}
            </div>
            <div className="login-lower-loginbox-wrapper">
              {renderLoginBox()}
            </div>
          </div>
          {/* <!-- The Modal --> */}
          <div
            id="myModal"
            className={modalShow === false ? "modal-hide" : "modal-show"}
          >
            {/* <!-- Modal content --> */}
            <div className="modal-content-cust-login">
              <div className="modal-header-cust">
                <span
                  className="modal-close-cust"
                  onClick={() => setModalShow(!modalShow)}
                >
                  &times;
                </span>
                <h5 className="fw-4">Tax Information</h5>
              </div>
              <div className="modal-body-cust">
                <div style={{ padding: "40px 20px" }}>
                  <ul
                    style={{ listStyleType: "none", margin: "0", padding: "0" }}
                  >
                    <li>
                      <a
                        href={tax1File}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: "1.2rem" }}
                      >
                        Tax Year 2021 – F8937 Common Shareholders Tax Form
                      </a>
                    </li>
                    <li>
                      <a
                        href={tax2File}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: "1.2rem" }}
                      >
                        Tax Year 2022 – F8937 Common Shareholders Tax Form
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="close_button_bottom">
                <button
                  onClick={() => setModalShow(!modalShow)}
                  className="modal_close_button"
                >
                  Close
                </button>
              </div>
            </div>
          </div>

          <LoginFooter />
        </>
      )}
    </>
  );
};

export default LoginPage;
